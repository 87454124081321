import { createSlice } from "@reduxjs/toolkit";

// Helper function to get the initial state
const getInitialState = () => ({
    balanceInfo: {
        balances: [],
        isWithdrawalContractBalanceBlocking: false,
        totalBalance: 0,
        totalContractBalance: 0,
        withdrawableBalance: 0,
    },
    isBalanceLoading: false,
});

const balanceSlice = createSlice({
    name: "balance",
    initialState: getInitialState(),
    reducers: {
        setBalanceInfo: (state, action) => {
            state.balanceInfo = action.payload;
        },
        setBalanceLoading: (state, action) => {
            state.isBalanceLoading = action.payload;
        },
        resetData(state) {
            Object.assign(state, getInitialState());
        },
    },
});

export default balanceSlice;
export const balanceActions = balanceSlice.actions;
