import { createSlice } from "@reduxjs/toolkit";

const promotionSlice = createSlice({
    name: "promotion",
    initialState: {
        topTabIndex: "1",
        myPromotionSubtab: "inProgress", // notStarted / inProgress / claimable / completed
        categories: [],
        promotions: [],
        selectedBonus: null,
        appliedPromotions: null,
        freePromotions: [],
        rebateData: null,
        rebateFilteredTotalAmount: null,
        ValidAppliedList: null,
        inValidAppliedList: null,
        backPromotionPage: false,
        isLoading: false,
    },
    reducers: {
        changeTab(state, action) {
            state.topTabIndex = action.payload;
        },
        setMyPromotionSubtab(state, action) {
            state.myPromotionSubtab = action.payload;
        },
        setSelectedBonus(state, action) {
            state.selectedBonus = action.payload;
        },
        setCategories(state, action) {
            state.categories = action.payload;
        },
        setPromotions(state, action) {
            state.promotions = action.payload;
        },
        setBackPromotionPage(state, action) {
            state.backPromotionPage = action.payload;
        },
        setRebateData(state, action) {
            console.log("action.payload", action.payload);
            state.rebateData = action.payload.data;
            if (action.payload.data.length) {
                let total = 0;
                if (action.payload.selectedCategory === "All") {
                    total = action.payload.data.reduce((acc, item) => {
                        acc += item.totalGivenAmount;
                        return acc;
                    }, 0);
                } else {
                    total = action.payload.data
                        .filter(
                            (item) =>
                                item.promotionCategory ===
                                action.payload.selectedCategory
                        )
                        .reduce((acc, item) => {
                            acc += item.totalGivenAmount;
                            return acc;
                        }, 0);
                }
                state.rebateFilteredTotalAmount = total;
            }
        },
        setRebateFilterTotalAmount(state, action) {
            state.rebateFilteredTotalAmount = action.payload;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        resetData(state) {
            state.topTabIndex = "1";
            state.myPromotionSubtab = "inProgress";
            state.categories = [];
            state.promotions = [];
            state.selectedBonus = null;
            state.appliedPromotions = null;
            state.freePromotions = [];
            state.rebateData = null;
            state.rebateFilteredTotalAmount = null;
            state.ValidAppliedList = null;
            state.inValidAppliedList = null;
            state.backPromotionPage = false;
            state.isLoading = false;
        },
    },
});

export default promotionSlice;

export const promotionActions = promotionSlice.actions;
