import { createSlice } from "@reduxjs/toolkit";

// Helper function to get the initial state
const getInitialState = () => ({
    memberInfo: {},
    isPersonalDataEditable: false, // 帳戶資料-基本訊息是否可編輯
    isNewMemFirstLogin: false,
});

const userCenterSlice = createSlice({
    name: "userCenter",
    initialState: getInitialState(),
    reducers: {
        setMemberInfo(state, action) {
            state.memberInfo = { ...state.memberInfo, ...action.payload };
            const { firstName, dob, gender } = action.payload;
            console.log("firstName", firstName, "dob", dob, "gender", gender);
            if (firstName && firstName.length && dob && gender) {
                state.isPersonalDataEditable = false;
            } else {
                state.isPersonalDataEditable = true;
            }
        },
        setMemberFirstLogin(state, action) {
            state.isNewMemFirstLogin = action.payload;
        },
        resetData(state) {
            Object.assign(state, getInitialState());
        },
    },
});

export default userCenterSlice;
export const userCenterActions = userCenterSlice.actions;
