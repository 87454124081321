import { createSlice } from "@reduxjs/toolkit";

// Redux Slice
const bonusSlice = createSlice({
    name: "bonus",
    initialState: {
        lockedBalanceVisible: false, // 控制 LockedBalance 的显示
        progressDetails: {
            totalContractBalance: 0,
            totalDepositContractBalance: 0,
            lastDepositContractUpdatedDate: null,
            contract: [],
        },
    },
    reducers: {
        setLockedBalanceVisible(state, action) {
            state.lockedBalanceVisible = action.payload;
        },
        setBonusProgress(state, action) {
            state.progressDetails = action.payload;
        },
    },
});

export default bonusSlice;

export const bonusActions = bonusSlice.actions;
