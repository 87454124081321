/*
 * @Author: Alan
 * @Date: 2023-05-09 18:01:56
 * @LastEditors: Alan
 * @LastEditTime: 2023-06-02 19:32:39
 * @Description: Redux store 的配置文件。该文件定义了 Redux store 并将多个 Reducer 合并到一个根 reducer 中。
 * @FilePath: \F1-M2-WEB-Code\store\store.js
 */

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import promotionSlice from "./promotionSlice";
import userCenterSlice from "./userCenterSlice";
import spinSlice from "./spinSlice";
import gameSlice from "./gameSlice";
import mainSiteConfigSlice from "./mainSiteConfigSlice";
import commonDataSlice from "./commonDataSlice";
import balanceSlice from "./balanceSlice";
import bonusSlice from "./bonusSlice";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
	DepositMisc,
    MethodsDetails,
    Methods,
    MethodsActive,
    DepositMoneyStatus,
    DepositMemberBanks,
    BanksBankAccounts,
    DepositBankSearch,
    DepositBankActive,
    DepositMemberBanksActive,
    DepositDateSelect,
    UploadFileList,
    UploadFileErr,
    DepositTimeSelect,
    DepositPayments,
    DepositNextStep,
    DepositTimer,
    MethodCodeActive,
    DepositSuccessPage,
    UploadFileStatus,
    DepositCardNumber_CC,
    DepositCardPIN_CC,
    SuggestedAmounts,
    MemberCancelDeposit,
    DepositOldBank,
    DepositOldBankSixNumberStatus,
    RemoveAllReducersState,
    CopyKey,
    DepositAccountByAmount,
    MemberInfo,
    Announcement,
	LoginOTP,
	ReverseBankDefault,
	DepositBouns,
	DepositTriggerFrom
} from "central-payment/Deposit/M2/store/reducers/DepositReducer";

const userReducer = combineReducers({
    promotion: promotionSlice.reducer,
    userCenter: userCenterSlice.reducer,
    spin: spinSlice.reducer,
    game: gameSlice.reducer,
    mainSiteConfig: mainSiteConfigSlice.reducer,
    commonData: commonDataSlice.reducer,
    balance: balanceSlice.reducer,
    bonus: bonusSlice.reducer,

    //中心支付
	DepositMisc: DepositMisc,
    MethodsDetails: MethodsDetails,
    Methods: Methods,
    MethodsActive: MethodsActive,
    DepositMoneyStatus: DepositMoneyStatus,
    DepositMemberBanks: DepositMemberBanks,
    BanksBankAccounts: BanksBankAccounts,
    DepositBankSearch: DepositBankSearch,
    DepositBankActive: DepositBankActive,
    DepositMemberBanksActive: DepositMemberBanksActive,
    DepositDateSelect: DepositDateSelect,
    UploadFileList: UploadFileList,
    UploadFileErr: UploadFileErr,
    DepositTimeSelect: DepositTimeSelect,
    DepositPayments: DepositPayments,
    DepositNextStep: DepositNextStep,
    DepositTimer: DepositTimer,
    MethodCodeActive: MethodCodeActive,
    DepositSuccessPage: DepositSuccessPage,
    UploadFileStatus: UploadFileStatus,
    DepositCardNumber_CC: DepositCardNumber_CC,
    DepositCardPIN_CC: DepositCardPIN_CC,
    SuggestedAmounts: SuggestedAmounts,
    MemberCancelDeposit: MemberCancelDeposit,
    DepositOldBank: DepositOldBank,
    DepositOldBankSixNumberStatus: DepositOldBankSixNumberStatus,
    RemoveAllReducersState: RemoveAllReducersState,
    CopyKey: CopyKey,
    DepositAccountByAmount: DepositAccountByAmount,
    MemberInfo: MemberInfo,
	Announcement: Announcement,
	LoginOTP: LoginOTP,
	ReverseBankDefault: ReverseBankDefault,
	DepositBouns:DepositBouns,
	DepositTriggerFrom:DepositTriggerFrom,
});
const promotionTransforms = createTransform(
    (inboundState) => {
        return {
            topTabIndex: inboundState.topTabIndex,
            myPromotionSubtab: inboundState.myPromotionSubtab,
        };
    },
    (outboundState) => {
        return { ...outboundState };
    },
    { whitelist: ["promotion"] }
);
const persistConfig = {
    key: "redux_persist_M2_Web",
    storage, // if needed, use a safer storage
    transforms: [promotionTransforms],
    whitelist: ["promotion", "userCenter"],
};
const persistedReducer = persistReducer(persistConfig, userReducer);
export const store = configureStore({
    reducer: persistedReducer,
});
export const persistor = persistStore(store);
